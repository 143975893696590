import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as SchedulesStore from '../../store/reducers/schedules'
import * as PanesStore from '../../store/reducers/panes'
import * as DrawerStore from '../../store/reducers/drawer'
import MasterPDF from './MasterPDF'
import InmatesPDF from './InmatesPDF'
import InstructorsPDF from './InstructorsPDF'
import AttendancePDF from './AttendancePDF'
import SigninPDF from './SigninPDF'
import CombinedPDF from './CombinedPDF'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Form,
  Select,
  Button,
  Divider,
  Modal,
  Container,
  Table,
  Popup,
  Label
} from 'semantic-ui-react'
const _ = require('underscore')

type ScheduleProps =
    { tab: string, mode: string } &
    SchedulesStore.SchedulesState & 
    typeof SchedulesStore.actionCreators &
    typeof PanesStore.actionCreators &
    typeof DrawerStore.actionCreators &
    WithRouterProps<{}>

class Schedules extends React.PureComponent<ScheduleProps> {
    public componentDidMount() {
        this.props.fetchFacilities()
    }

    private dayOfWeek3 = (day:number) => {
        switch(day) {
          case 0: {
            return 'Sun'
          }
          case 1: {
            return 'Mon'
          }
          case 2: {
            return 'Tue'
          }
          case 3: {
            return 'Wed'
          }
          case 4: {
            return 'Thu'
          }
          case 5: {
            return 'Fri'
          }
          case 6: {
            return 'Sat'
          }
          default: {
            return ''
          }
        }
    }

    public render() {
      var selected = []
      if (this.props.data.listings.length == 0) { selected.push('No listings selected') }
      if (this.props.data.facilities.length == 0) { selected.push('No facilities selected') } 
      if (this.props.data.printDates.length == 0 || (this.props.data.printDates == 'custom' && (!this.props.data.dateStart || !this.props.data.dateEnd))) { selected.push('No dates selected') } 
      return (
        <Fragment>
          <Form>
            <Form.Group>
              <Form.Field
                control={Select}
                label='Listings'
                placeholder='Listings'
                options={[{ key: 0, value: 'master', text: 'Master schedule' }, { key: 1, value: 'inmates', text: 'Inmates schedule' }, { key: 2, value: 'instructors', text: 'Instructors schedule' }, { key: 3, value: 'attendance', text: 'Attendance checklist' }, { key: 4, value: 'signin', text: 'Signin sheets' }]}
                value={this.props.data.listings || []}
                onChange={(e:any, data:any) => this.props.editScheduleField('listings', data.value as string)}
                width={13}
                multiple
              />
              <Form.Field
                control={Select}
                label='Combine listings'
                placeholder='Combine listings'
                options={[{ key: 0, value: false, text: 'No' }, { key: 1, value: true, text: 'Yes' }]}
                value={this.props.data.combine}
                onChange={(e:any, data:any) => this.props.editScheduleField('combine', data.value as string)}
              />
              <Form.Field
                control={Select}
                label='Data omissions'
                placeholder='Data omissions'
                options={[{ key: 0, value: 'N', text: 'No' }, { key: 1, value: 'Y', text: 'Yes' }, { key: 2, value: 'O', text: 'Only' }]}
                value={this.props.data.omissions}
                onChange={(e:any, data:any) => this.props.editScheduleField('omissions', data.value as string)}
              />
            </Form.Group>
            {this.props.data.listings.length == 0 ? null : <Form.Group>
              {this.props.data.listings.includes('master') ? <Form.Field
                control={Select}
                label='Master options'
                placeholder='Master options'
                options={[{ key: 0, value: 'inmate', text: 'Sort by inmate' }, { key: 1, value: 'day', text: 'Sort by day' }, { key: 2, value: 'class', text: 'Sort by class' }, { key: 3, value: 'instructors', text: 'Include instructors' }]}
                value={this.props.data.masterOptions}
                onChange={(e:any, data:any) => this.props.editScheduleField('masterOptions', data.value as string)}
                width={4}
                multiple
              /> : null}
              {this.props.data.listings.includes('inmates') ? <Form.Field
                control={Select}
                label='Inmate options'
                placeholder='Inmate options'
                options={[{ key: 0, value: 'shift', text: 'Sort by shift' }, { key: 1, value: 'class', text: 'Sort by class' }, { key: 2, value: 'time', text: 'Sort by time' }, { key: 3, value: 'name', text: 'Sort by name' }, { key: 4, value: 'housing', text: 'Sort by housing' }, { key: 5, value: 'instructors', text: 'Include instructors' }]}
                value={this.props.data.inmateOptions}
                onChange={(e:any, data:any) => this.props.editScheduleField('inmateOptions', data.value as string)}
                width={4}
                multiple
              /> : null}
              {this.props.data.listings.includes('instructors') ? <Form.Field
                control={Select}
                label='Instructor options'
                placeholder='Instructor options'
                options={[{ key: 0, value: 'time', text: 'Time' },{ key: 1, value: 'name', text: 'Name' }]}
                value={this.props.data.instructorOptions}
                onChange={(e:any, data:any) => this.props.editScheduleField('instructorOptions', data.value as string)}
                width={4}
                multiple
              /> : null}
              {this.props.data.listings.includes('attendance') ? <Form.Field
                control={Select}
                label='Attendance options'
                placeholder='Attendance options'
                options={[{ key: 0, value: 'instructors', text: 'Include instructors' }]}
                value={this.props.data.attendanceOptions}
                onChange={(e:any, data:any) => this.props.editScheduleField('attendanceOptions', data.value as string)}
                width={4}
                multiple
              /> : null}
              {this.props.data.listings.includes('signin') ? <Form.Field
                control={Select}
                label='Signin options'
                placeholder='Signin options'
                options={[{ key: 0, value: 'instructors', text: 'Include instructors' }]}
                value={this.props.data.signinOptions}
                onChange={(e:any, data:any) => this.props.editScheduleField('signinOptions', data.value as string)}
                width={4}
                multiple
              /> : null}
            </Form.Group>}
            <Form.Group>
              <Form.Field
                control={Select}
                label='Dates to print'
                placeholder='Dates to print'
                options={[{ key: 0, value: 'today', text: 'Today' }, { key: 1, value: 'tomorrow', text: 'Tomorrow' }, { key: 2, value: 'rest', text: 'Rest of this week' }, { key: 3, value: 'next', text: 'Next week' }, { key: 4, value: 'custom', text: 'Custom range' }]}
                value={this.props.data.printDates}
                onChange={(e:any, data:any) => this.props.editScheduleField('printDates', data.value as string)}
                width={4}
              />
              {this.props.data.printDates != 'custom' ? null : <Fragment>
                <Form.Field
                  control={DatePicker}
                  label="Start date"
                  name="dateStart"
                  placeholder="MM/DD/YYYY"
                  selected={this.props.data.dateStart ? new Date(this.props.data.dateStart) : null}
                  onChange={(date:any) => this.props.editScheduleField('dateStart', date ? format(date, 'MM/dd/yyyy') : null)}
                  width={4}
                />
                <Form.Field
                  control={DatePicker}
                  label="End date"
                  name="dateEnd"
                  placeholder="MM/DD/YYYY"
                  selected={this.props.data.dateEnd ? new Date(this.props.data.dateEnd) : null}
                  onChange={(date:any) => this.props.editScheduleField('dateEnd', date ? format(date, 'MM/dd/yyyy') : null)}
                  width={4}
                />
              </Fragment>}
              <Form.Field
                control={Select}
                label='Orientation'
                placeholder='Orientation'
                options={[{ key: 0, value: 'portrait', text: 'Portrait' }, { key: 1, value: 'landscape', text: 'Landscape' }]}
                value={this.props.data.orientation}
                onChange={(e:any, data:any) => this.props.editScheduleField('orientation', data.value as string)}
                width={4}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Select}
                label='Facilities to print'
                placeholder='Facilities to print'
                options={this.props.options.facilities}
                value={this.props.data.facilities || []}
                onChange={(e:any, data:any) => this.props.editScheduleField('facilities', data.value as string)}
                width={5}
                search
                multiple
              />
              <Form.Field
                control={Select}
                label='Staff to print'
                placeholder='Staff to print'
                options={this.props.options.staff}
                value={this.props.data.staff || []}
                onChange={(e:any, data:any) => this.props.editScheduleField('staff', data.value as string)}
                width={6}
                search
                multiple
              />
              <Form.Field
                control={Select}
                label='Housing to print'
                placeholder='Housing to print'
                options={this.props.options.housing}
                value={this.props.data.housing || []}
                onChange={(e:any, data:any) => this.props.editScheduleField('housing', data.value as string)}
                width={5}
                search
                multiple
              />
            </Form.Group>
            {selected.length == 0 ? <Button color='blue' content='Print schedules' onClick={this.props.calculateMissing} /> : <Popup position='right center' content={selected.join(' / ')} trigger={<Button content='Print schedules' />} />}
            <Button content='Clear data' onClick={this.props.clearData} />
            {this.props.modals.print.open ? <Fragment>
              <Divider />
              <Container>
                {this.props.modals.print.ready ? null : <div>Analyzing schedules...</div>}
                {this.props.modals.print.ready && this.props.modals.missing.data.error ? <div>{this.props.modals.missing.data.error}</div> : null}
                {this.props.modals.print.ready && !this.props.modals.missing.data.error && this.props.modals.missing.data.noSchedules.inmates.length == 0 && this.props.modals.missing.data.missingSchedules.inmates.length == 0 && this.props.modals.missing.data.transfers.inmates.length == 0 && this.props.modals.missing.data.instructors.length == 0 ? <div>All inmates and instructors accounted for. Go ahead and <b>print</b> your schedule.</div> : null}
                {this.props.modals.print.ready && !this.props.modals.missing.data.error && this.props.modals.missing.data.instructors.length > 0 ? <div>There {this.props.modals.missing.data.instructors.length == 1 ? `is` : `are`} <b>{this.props.modals.missing.data.instructors.length}</b> {this.props.modals.missing.data.instructors.length == 1 ? `class` : `classes`} that {this.props.modals.missing.data.instructors.length == 1 ? `has` : `have`} <b>no instructors</b> assigned.</div> : null}
                {this.props.modals.print.ready && !this.props.modals.missing.data.error && this.props.modals.missing.data.noSchedules.inmates.length > 0 ? <div>There {this.props.modals.missing.data.noSchedules.inmates.length == 1 ? `is` : `are`} <b>{this.props.modals.missing.data.noSchedules.inmates.length}</b> {this.props.modals.missing.data.noSchedules.inmates.length == 1 ? `inmate` : `inmates`} within <b>{this.props.modals.missing.data.noSchedules.classes}</b> {this.props.modals.missing.data.noSchedules.classes == 1 ? `class` : `classes`} that {this.props.modals.missing.data.noSchedules.inmates.length == 1 ? `has` : `have`} <b>no schedules</b> assigned.</div> : null}
                {this.props.modals.print.ready && !this.props.modals.missing.data.error && this.props.modals.missing.data.missingSchedules.inmates.length > 0 ? <div>There {this.props.modals.missing.data.missingSchedules.inmates.length == 1 ? `is` : `are`} <b>{this.props.modals.missing.data.missingSchedules.inmates.length}</b> {this.props.modals.missing.data.missingSchedules.inmates.length == 1 ? `inmate` : `inmates`} within <b>{this.props.modals.missing.data.missingSchedules.classes}</b> {this.props.modals.missing.data.missingSchedules.classes == 1 ? `class` : `classes`} that {this.props.modals.missing.data.missingSchedules.inmates.length == 1 ? `has` : `have`} <b>blank schedules</b>.</div> : null}
                {this.props.modals.print.ready && !this.props.modals.missing.data.error && this.props.modals.missing.data.transfers.inmates.length > 0 ? <div>There {this.props.modals.missing.data.transfers.inmates.length == 1 ? `is` : `are`} <b>{this.props.modals.missing.data.transfers.inmates.length}</b> {this.props.modals.missing.data.transfers.inmates.length == 1 ? `inmate` : `inmates`} within <b>{this.props.modals.missing.data.transfers.classes}</b> {this.props.modals.missing.data.transfers.classes == 1 ? `class` : `classes`} that {this.props.modals.missing.data.transfers.inmates.length == 1 ? `has` : `have`} <b>been transferred</b>.</div> : null}
                {this.props.modals.print.ready && !this.props.modals.missing.data.error && (this.props.modals.missing.data.noSchedules.inmates.length > 0 || this.props.modals.missing.data.missingSchedules.inmates.length > 0 || this.props.modals.missing.data.transfers.inmates.length > 0 || this.props.modals.missing.data.instructors.length > 0) ? <div><br/><i>Note: These inmates in those classes will not be printed on the schedule and signin sheets for those times.</i><br/></div> : null}
                {this.props.modals.print.ready && !this.props.modals.missing.data.error && (this.props.modals.missing.data.noSchedules.inmates.length > 0 || this.props.modals.missing.data.missingSchedules.inmates.length > 0 || this.props.modals.missing.data.transfers.inmates.length > 0 || this.props.modals.missing.data.instructors.length > 0) ? <div><br/>Press <b>print</b> to <b>acknowledge</b> or return and make corrections.</div> : null}
                {this.props.modals.print.ready && !this.props.modals.missing.data.error ? (selected.length == 0 ? <Button color='blue' content='Print' onClick={this.props.generateSchedules} style={{marginTop:10}} /> : <Popup position='right center' content={selected.join(' / ')} trigger={<Button content='Print' style={{marginTop:10}} />} />) : null}
                {this.props.modals.print.ready && !this.props.modals.missing.data.error && (this.props.modals.missing.data.noSchedules.inmates.length > 0 || this.props.modals.missing.data.missingSchedules.inmates.length > 0 || this.props.modals.missing.data.transfers.inmates.length > 0 || this.props.modals.missing.data.instructors.length > 0) ? <Button content="Missing Data" color="orange" onClick={this.props.toggleMissingModal} style={{marginTop:10}} /> : null}
              </Container>
            </Fragment> : <Divider />}
            <div id='pdfs'>
              <MasterPDF pdf={this.props.pdfs.master} options={this.props.data.masterOptions} orientation={this.props.data.orientation} missing={this.props.modals.missing.data} />
              <InmatesPDF pdf={this.props.pdfs.inmates} options={this.props.data.inmateOptions} orientation={this.props.data.orientation} missing={this.props.modals.missing.data} />
              <InstructorsPDF pdf={this.props.pdfs.instructors} options={this.props.data.instructorOptions} orientation={this.props.data.orientation} missing={this.props.modals.missing.data} />
              <AttendancePDF pdf={this.props.pdfs.attendance} options={this.props.data.attendanceOptions} orientation={this.props.data.orientation}  missing={this.props.modals.missing.data}/>
              <SigninPDF pdf={this.props.pdfs.signin} options={this.props.data.signinOptions} orientation={this.props.data.orientation} missing={this.props.modals.missing.data} />
              <CombinedPDF pdf={this.props.pdfs.combined} listings={this.props.data.listings} options={{ master: this.props.data.masterOptions, inmates: this.props.data.inmateOptions, instructors: this.props.data.instructorOptions, attendance: this.props.data.attendanceOptions, signin: this.props.data.signinOptions }} orientation={this.props.data.orientation} missing={this.props.modals.missing.data} />
            </div>
          </Form>
          {this.props.modals.missing.open ? <Fragment>
            <Divider />
            <Container style={{height:340,overflowY:'scroll',borderBottom:'1px solid rgba(34, 36, 38, 0.1)'}}>
              {this.props.modals.missing.open && this.props.modals.missing.data.instructors.length > 0 ? <Table compact celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.Cell warning colSpan={3} style={{padding:'5px 7px',borderBottom:'1px solid rgba(34,36,38,.1)'}}><b>Missing Instructors</b></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell width={2}>Class ID</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Facility</Table.HeaderCell>
                    <Table.HeaderCell>Class Name</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.modals.missing.data.instructors.map((data:any) => (
                    <Table.Row key={data.classID}>
                      <Table.Cell>
                        {this.props.mode == 'dual' ? <Popup
                          hideOnScroll
                          on='click'
                          trigger={<div className='link' style={{whiteSpace:'normal'}}>{data.classID}</div>}
                        >
                          <Popup.Header>Open in pane</Popup.Header>
                          <Popup.Content>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(1, data.classID) }}>1</Label>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(2, data.classID) }}>2</Label>
                          </Popup.Content>
                        </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, data.classID) }}>{data.classID}</div>}
                      </Table.Cell>
                      <Table.Cell>{data.facilityAbbr}</Table.Cell>
                      <Table.Cell>{data.className}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table> : null}
              {this.props.modals.missing.open && this.props.modals.missing.data.hiatus.length > 0 ? <Table compact celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.Cell warning colSpan={3} style={{padding:'5px 7px',borderBottom:'1px solid rgba(34,36,38,.1)'}}><b>Classes on Hiatus</b></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell width={2}>Class ID</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Facility</Table.HeaderCell>
                    <Table.HeaderCell>Class Name</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.modals.missing.data.hiatus.map((data:any) => (
                    <Table.Row key={data.classID}>
                      <Table.Cell>
                        {this.props.mode == 'dual' ? <Popup
                          hideOnScroll
                          on='click'
                          trigger={<div className='link' style={{whiteSpace:'normal'}}>{data.classID}</div>}
                        >
                          <Popup.Header>Open in pane</Popup.Header>
                          <Popup.Content>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(1, data.classID) }}>1</Label>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(2, data.classID) }}>2</Label>
                          </Popup.Content>
                        </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, data.classID) }}>{data.classID}</div>}
                      </Table.Cell>
                      <Table.Cell>{data.facilityAbbr}</Table.Cell>
                      <Table.Cell>{data.className}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table> : null}
              {this.props.modals.missing.open && this.props.modals.missing.data.transfers.inmates.length > 0 ? <Table compact celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.Cell warning colSpan={4} style={{padding:'5px 7px',borderBottom:'1px solid rgba(34,36,38,.1)'}}><b>Transferred Students</b></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell width={2}>Class ID</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Facility</Table.HeaderCell>
                    <Table.HeaderCell width={6}>Class Name</Table.HeaderCell>
                    <Table.HeaderCell width={6}>Inmate Name</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.modals.missing.data.transfers.inmates.map((data:any) => (
                    <Table.Row key={data.rosterID}>
                      <Table.Cell>
                        {this.props.mode == 'dual' ? <Popup
                          hideOnScroll
                          on='click'
                          trigger={<div className='link' style={{whiteSpace:'normal'}}>{data.classID}</div>}
                        >
                          <Popup.Header>Open in pane</Popup.Header>
                          <Popup.Content>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(1, data.classID) }}>1</Label>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(2, data.classID) }}>2</Label>
                          </Popup.Content>
                        </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, data.classID) }}>{data.classID}</div>}
                      </Table.Cell>
                      <Table.Cell>{data.facilityAbbr}</Table.Cell>
                      <Table.Cell>{data.className}</Table.Cell>
                      <Table.Cell>{data.inmateName}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table> : null}
              {this.props.modals.missing.open && this.props.modals.missing.data.noSchedules.inmates.length > 0 ? <Table compact celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.Cell warning colSpan={4} style={{padding:'5px 7px',borderBottom:'1px solid rgba(34,36,38,.1)'}}><b>No Schedules</b></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell width={2}>Class ID</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Facility</Table.HeaderCell>
                    <Table.HeaderCell width={6}>Class Name</Table.HeaderCell>
                    <Table.HeaderCell width={6}>Inmate Name</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.modals.missing.data.noSchedules.inmates.map((data:any) => (
                    <Table.Row key={data.rosterID}>
                      <Table.Cell>
                        {this.props.mode == 'dual' ? <Popup
                          hideOnScroll
                          on='click'
                          trigger={<div className='link' style={{whiteSpace:'normal'}}>{data.classID}</div>}
                        >
                          <Popup.Header>Open in pane</Popup.Header>
                          <Popup.Content>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(1, data.classID) }}>1</Label>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(2, data.classID) }}>2</Label>
                          </Popup.Content>
                        </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, data.classID) }}>{data.classID}</div>}
                      </Table.Cell>
                      <Table.Cell>{data.facilityAbbr}</Table.Cell>
                      <Table.Cell>{data.className}</Table.Cell>
                      <Table.Cell>{data.inmateName}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table> : null}
              {this.props.modals.missing.open && this.props.modals.missing.data.missingSchedules.inmates.length > 0 ? <Table compact celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.Cell warning colSpan={5} style={{padding:'5px 7px',borderBottom:'1px solid rgba(34,36,38,.1)'}}><b>Blank Schedules</b></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell width={2}>Class ID</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Facility</Table.HeaderCell>
                    <Table.HeaderCell width={6}>Class Name</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Inmate Name</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Missing Days</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.modals.missing.data.missingSchedules.inmates.map((data:any) => (
                    <Table.Row key={data.rosterID}>
                      <Table.Cell>
                        {this.props.mode == 'dual' ? <Popup
                          hideOnScroll
                          on='click'
                          trigger={<div className='link' style={{whiteSpace:'normal'}}>{data.classID}</div>}
                        >
                          <Popup.Header>Open in pane</Popup.Header>
                          <Popup.Content>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(1, data.classID) }}>1</Label>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(2, data.classID) }}>2</Label>
                          </Popup.Content>
                        </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, data.classID) }}>{data.classID}</div>}
                      </Table.Cell>
                      <Table.Cell>{data.facilityAbbr}</Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.className}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.inmateName}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.map(data.missingSchedules, (schedule:any, s:number) => { return <div key={s} style={schedule.missing ? {fontWeight:'bold',color:'#db2828'} : {}}>{this.dayOfWeek3(schedule.dayOfWeek)}. {schedule.startTime} - {schedule.endTime}</div> })}</div></Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table> : null}
            </Container>
          </Fragment> : null}
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { ...state.schedules, tab: state.drawer.tab, mode: state.panes.mode } },
  { ...SchedulesStore.actionCreators, ...PanesStore.actionCreators, ...DrawerStore.actionCreators }
)(Schedules as any)