import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import Cleave from "cleave.js/react"
import {
  Container,
  Icon,
  Search,
  Button,
  Grid,
  Header,
  Tab,
  Label,
  Popup,
  Table,
  Progress
} from 'semantic-ui-react'
const _ = require('underscore')

type PaneProps =
    { career: PanesStore.Career, num: 1 | 2, print: boolean, mode: string } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class PathwayPane extends React.PureComponent<PaneProps> {
    private codeColor(num: string): string {
        switch (num) {
            case "1":
                return '#2185d0';
            case "2":
                return '#2185d0';
            case "3":
                return '#21ba45';
            case "4":
                return '#21ba45';
            case "5":
                return '#21ba45';
            case "6":
                return '#db2828';
            case "7":
                return '#db2828';
            default:
                return '#767676';
        }
    }

    public render() {
        return (
          <Container fluid>
            {this.props.print ? null : <div className='close' style={{position:'absolute',top:0,left:0,zIndex:1}}>
              <Button circular color='red' icon='close' size='mini' onClick={() => this.props.togglePane(this.props.num)} />
            </div>}
            <Button color='blue' circular icon='print' onClick={() => this.props.togglePrint(this.props.num)} style={{float:'right',fontSize:'1.2em',marginTop:15}} />
            <Grid style={{marginBottom:10}}>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column>
                  <Header as='h2' style={{display:'inline-block',marginTop:0,width:750,whiteSpace:'normal'}}><div><Icon name='suitcase' /> {this.props.career.data.name}</div></Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div style={{marginTop:20,marginBottom:10,whiteSpace:'normal'}}>{this.props.career.data.narrative}</div>
            <Tab menu={{ secondary: true, pointing: true }} panes={[
              { menuItem: 'Students', render: () => <Fragment>
                <Header as='h3'>Assigned</Header>
                <Search
                  loading={this.props.career.inmate.loading}
                  onResultSelect={(e:any, data:any) => this.props.selectCareerInmate(this.props.num, data.result)}
                  onSearchChange={(e:any, data:any) => this.props.searchCareers(this.props.num, data.value as string)}
                  results={_.filter(this.props.career.options.inmates, (inmate:any) => _.any(this.props.career.data.recommendations, (i:any) => inmate.key == i.recordID))}
                  value={this.props.career.inmate.value}
                  style={{display:'inline-block'}}
                />
                <Table compact celled striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Facility</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Code</Table.HeaderCell>
                      <Table.HeaderCell width={5}>Progress</Table.HeaderCell>
                      <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {_.map(this.props.career.info.inmates, (inmate:any, i:number) => <Table.Row key={i}>
                      <Table.Cell>
                        {this.props.mode == 'dual' ? <Popup
                          hideOnScroll
                          on='click'
                          trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block'}}>{inmate.name}</div>}
                        >
                          <Popup.Header>Open in pane</Popup.Header>
                          <Popup.Content>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(1, inmate.recordID, 8) }}>1</Label>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(2, inmate.recordID, 8) }}>2</Label>
                          </Popup.Content>
                        </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block'}} onClick={() => { this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, inmate.recordID, 8) }}>{inmate.name}</div>}
                      </Table.Cell>
                      <Table.Cell>{inmate.facility}</Table.Cell>
                      <Table.Cell>{_.map(inmate.code, (char:string, c:number) => <span style={{fontWeight:'bold',color:this.codeColor(inmate.weights[c])}}>{char}</span>)}</Table.Cell>
                      <Table.Cell>{inmate.percent == 0 ? null : <Progress indicating percent={inmate.percent} style={{margin:0}} />}</Table.Cell>
                      <Table.Cell>
                        <Button negative size='mini' content='Remove' onClick={() => this.props.removeCareerInmate(this.props.num, inmate.recordID)} />
                      </Table.Cell>
                    </Table.Row>)}
                  </Table.Body>
                </Table>
                {this.props.print ? null : <Button positive content='Save' onClick={_.debounce(() => this.props.saveCareer(this.props.num, 'pathway'), 10000, true)} />}
                <Header as='h3'>Recommended</Header>
                <Table compact celled striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Facility</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Code</Table.HeaderCell>
                      <Table.HeaderCell width={4}>Progress</Table.HeaderCell>
                      <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {_.any(this.props.career.data.recommendations, (inmate:any) => inmate.waitlist) ? <Fragment>
                      <Table.Row positive>
                        <Table.Cell colSpan={3} style={{ fontWeight: 700, padding: '.5em' }}>Waitlisted</Table.Cell>
                      </Table.Row>
                      {_.filter(this.props.career.data.recommendations, (inmate:any) => inmate.waitlist).map((inmate:any, i:number) => <Table.Row key={i}>
                        <Table.Cell>
                          {this.props.mode == 'dual' ? <Popup
                            hideOnScroll
                            on='click'
                            trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block'}}>{inmate.name}</div>}
                          >
                            <Popup.Header>Open in pane</Popup.Header>
                            <Popup.Content>
                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(1, inmate.recordID, 8) }}>1</Label>
                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(2, inmate.recordID, 8) }}>2</Label>
                            </Popup.Content>
                          </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block'}} onClick={() => { this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, inmate.recordID, 8) }}>{inmate.name}</div>}
                        </Table.Cell>
                        <Table.Cell>{inmate.facility}</Table.Cell>
                        <Table.Cell>{_.map(inmate.code, (char:string, c:number) => <span style={{fontWeight:'bold',color:this.codeColor(inmate.weights[c])}}>{char}</span>)}</Table.Cell>
                        <Table.Cell>{inmate.percent == 0 ? null : <Progress indicating percent={inmate.percent} style={{margin:0}} />}</Table.Cell>
                        <Table.Cell>
                          <Button positive size='mini' content='Add' onClick={() => this.props.selectCareerInmate(this.props.num, { key: inmate.recordID, title: inmate.name })} />
                        </Table.Cell>
                      </Table.Row>)}
                    </Fragment> : null}
                    {_.any(this.props.career.data.recommendations, (inmate:any) => !inmate.waitlist) ? <Fragment>
                      <Table.Row positive>
                        <Table.Cell colSpan={3} style={{ fontWeight: 700, padding: '.5em' }}>Available</Table.Cell>
                      </Table.Row>
                      {_.filter(this.props.career.data.recommendations, (inmate:any) => !inmate.waitlist).map((inmate:any, i:number) => <Table.Row key={i}>
                        <Table.Cell>
                          {this.props.mode == 'dual' ? <Popup
                            hideOnScroll
                            on='click'
                            trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block'}}>{inmate.name}</div>}
                          >
                            <Popup.Header>Open in pane</Popup.Header>
                            <Popup.Content>
                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(1, inmate.recordID, 8) }}>1</Label>
                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(2, inmate.recordID, 8) }}>2</Label>
                            </Popup.Content>
                          </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block'}} onClick={() => { this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, inmate.recordID, 8) }}>{inmate.name}</div>}
                        </Table.Cell>
                        <Table.Cell>{inmate.facility}</Table.Cell>
                        <Table.Cell>{_.map(inmate.code, (char:string, c:number) => <span style={{fontWeight:'bold',color:this.codeColor(inmate.weights[c])}}>{char}</span>)}</Table.Cell>
                        <Table.Cell>{inmate.percent == 0 ? null : <Progress indicating percent={inmate.percent} style={{margin:0}} />}</Table.Cell>
                        <Table.Cell>
                          <Button positive size='mini' content='Add' onClick={() => this.props.selectCareerInmate(this.props.num, { key: inmate.recordID, title: inmate.name })} />
                        </Table.Cell>
                      </Table.Row>)}
                    </Fragment> : null}
                  </Table.Body>
                </Table>
              </Fragment> },
              { menuItem: 'Classes', render: () => <div style={{whiteSpace:'normal'}}>
                <Table compact celled striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Class</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.career.data.templates.map((template:any, t:number) => <Table.Row positive={template.status == 'active'} warning={template.status == 'scheduled'} error={template.status != 'active' && template.status != 'scheduled'} key={t}>
                      <Table.Cell>
                        {this.props.mode == 'dual' ? <Popup
                          hideOnScroll
                          on='click'
                          trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block'}}>{template.name}</div>}
                        >
                          <Popup.Header>Open in pane</Popup.Header>
                          <Popup.Content>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(1, template.recordID) }}>1</Label>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(2, template.recordID) }}>2</Label>
                          </Popup.Content>
                        </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block'}} onClick={() => { this.props.fetchTemplate(this.props.mode == 'left' ? 1 : 2, template.recordID) }}>{template.name}</div>}
                      </Table.Cell>
                      <Table.Cell>{template.status == 'active' ? 'Active' : template.status == 'scheduled' ? 'Scheduled' : 'Inactive'}</Table.Cell>
                    </Table.Row>)}
                  </Table.Body>
                </Table>
              </div> },
            ]} /> 
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { career: ownProps.career, num: ownProps.num, print: ownProps.options.print, mode: state.panes.mode } },
  PanesStore.actionCreators
)(PathwayPane as any)
