import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as ReportsStore from '../../store/reducers/reports'
import * as PanesStore from '../../store/reducers/panes'
import Attendance from './Attendance'
import Quarterly from './Quarterly'
import AttendancePDF from './pdf/Attendance'
import CsprPDF from './pdf/CSPR'
import DescriptionsPDF from './pdf/Descriptions'
import EdHistoryPDF from './pdf/EdHistory'
import RecidivismPDF from './pdf/Recidivism'
import FundingPDF from './pdf/Funding'
import GedPDF from './pdf/GED'
import GraduatedPDF from './pdf/Graduated'
import HiSETPDF from './pdf/HiSET'
import KamakaniPDF from './pdf/Kamakani'
import QuarterlyPDF from './pdf/Quarterly'
import QuarterlyCSV from './csv/Quarterly'
import RosterPDF from './pdf/Roster'
import TabePDF from './pdf/TABE'
import TracingPDF from './pdf/Tracing'
import UtilizationPDF from './pdf/Utilization'
import Roster from './Roster'
import TABE from './TABE'
import GED from './GED'
import HiSET from './HiSET'
import RIASEC from './RIASEC'
import Graduated from './Graduated'
import Kamakani from './Kamakani'
import Descriptions from './Descriptions'
import Utilization from './Utilization'
import Tracing from './Tracing'
import CSPR from './CSPR'
import Funding from './Funding'
import EdHistory from './EdHistory'
import Recidivism from './Recidivism'
import ANDs from './ANDs'
import {
  Form,
  Select,
  Divider,
  Container,
  Icon
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { tab: string, staff: any } &
    ReportsStore.ReportsState & 
    typeof ReportsStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class Reports extends React.PureComponent<ReportProps> {
    public componentDidMount() {
        this.props.fetchFacilities()
        this.props.fetchEthnicities()
        this.props.fetchFavorites()
        this.props.socketConnect()
    }

    public componentWillUnmount() {
        this.props.socketDisconnect()
    }

    public render() {
      return (
        <Fragment>
          <Form>
            <Form.Group>
              {window.location.host.split(".")[0] == "sms" ? <Form.Field
                control={Select}
                placeholder='Report'
                options={_.filter(_.sortBy([{ key: 0, value: 'attendance', text: 'Attendance' },{ key: 1, value: 'utilization', text: 'Classroom utilization' },{ key: 2, value: 'tracing', text: 'Contact tracing' },{ key: 3, value: 'cspr', text: 'CSPR' },{ key: 4, value: 'ged', text: 'GED tests' },{ key: 5, value: 'hiset', text: 'HiSET tests' },{ key: 6, value: 'kamakani', text: 'Kamakani report' },{ key: 7, value: 'descriptions', text: 'Class descriptions' },{ key: 8, value: 'quarterly', text: 'Quarterly report' },{ key: 9, value: 'roster', text: 'Roster' },{ key: 10, value: 'tabe', text: 'TABE tests' },{ key: 11, value: 'funding', text: 'Funding sources' },{ key: 12, value: 'edhistory', text: 'Educational History' },{key: 13, value: 'graduated', text: 'Graduated'},{key: 14, value: 'riasec', text: 'RIASEC tests'}], (option:any) => option.text), (option:any) => this.props.staff.reports.includes(option.value))}
                value={this.props.report}
                onChange={(e:any, data:any) => this.props.changeReport(data.value as string)}
                width={4}
              /> : null}
              {window.location.host.split(".")[0] == "sas" ? <Form.Field
                control={Select}
                placeholder='Report'
                options={_.sortBy([{ key: 0, value: 'ands', text: 'A&Ds' }], (option:any) => option.text)}
                value={this.props.report}
                onChange={(e:any, data:any) => this.props.changeReport(data.value as string)}
                width={4}
              /> : null}
              {this.props.report ? _.any(this.props.favorites, (fav:any) => (this.props.reports[this.props.report].data.reportType ? fav.type == `${this.props.report}:${this.props.reports[this.props.report].data.reportType}` : fav.type == this.props.report)) ? <Icon className='favorite' name='star' color='blue' onClick={() => this.props.reports[this.props.report].data.reportType ? this.props.unfavorite(`${this.props.report}:${this.props.reports[this.props.report].data.reportType}`) : this.props.unfavorite(this.props.report)} style={{fontSize:'2em',marginTop:11,marginLeft:22}} /> : <Icon className='favorite' name='star outline' color='blue' onClick={() => this.props.reports[this.props.report].data.reportType ? this.props.favorite(`${this.props.report}:${this.props.reports[this.props.report].data.reportType}`, `${this.props.report.charAt(0).toUpperCase() + this.props.report.slice(1)} ${this.props.reports[this.props.report].data.reportType.charAt(0).toUpperCase() + this.props.reports[this.props.report].data.reportType.slice(1)} Report`) : this.props.favorite(this.props.report, `${this.props.report.charAt(0).toUpperCase() + this.props.report.slice(1)} Report`)} style={{fontSize:'2em',marginTop:11,marginLeft:22}} /> : <Icon className='favorite' name='star outline' color='blue' style={{fontSize:'2em',marginTop:11,marginLeft:22}} />}
            </Form.Group>
          </Form>
          <Container fluid style={{marginTop:20}}>
            {this.props.report == 'attendance' ? <Attendance /> : null}
            {this.props.report == 'quarterly' ? <Quarterly /> : null}
            {this.props.report == 'roster' ? <Roster /> : null}
            {this.props.report == 'tabe' ? <TABE /> : null}
            {this.props.report == 'ged' ? <GED /> : null}
            {this.props.report == 'graduated' ? <Graduated /> : null}
            {this.props.report == 'hiset' ? <HiSET /> : null}
            {this.props.report == 'riasec' ? <RIASEC /> : null}
            {this.props.report == 'kamakani' ? <Kamakani /> : null}
            {this.props.report == 'descriptions' ? <Descriptions /> : null}
            {this.props.report == 'utilization' ? <Utilization /> : null}
            {this.props.report == 'tracing' ? <Tracing /> : null}
            {this.props.report == 'cspr' ? <CSPR /> : null}
            {this.props.report == 'funding' ? <Funding /> : null}
            {this.props.report == 'edhistory' ? <EdHistory /> : null}
            {this.props.report == 'recidivism' ? <Recidivism /> : null}
            {this.props.report == 'ands' ? <ANDs /> : null}
          </Container>
          <Divider />
          {this.props.reports[this.props.report] && this.props.reports[this.props.report].data.displayType == 'pdf' ? <div id='pdfs'>
            {this.props.report == 'attendance' ? <AttendancePDF /> : null}
            {this.props.report == 'cspr' ? <CsprPDF /> : null}
            {this.props.report == 'descriptions' ? <DescriptionsPDF /> : null}
            {this.props.report == 'funding' ? <FundingPDF /> : null}
            {this.props.report == 'ged' ? <GedPDF /> : null}
            {this.props.report == 'graduated' ? <GraduatedPDF /> : null}
            {this.props.report == 'hiset' ? <HiSETPDF /> : null}
            {this.props.report == 'kamakani' ? <KamakaniPDF /> : null}
            {this.props.report == 'quarterly' ? <QuarterlyPDF /> : null}
            {this.props.report == 'roster' ? <RosterPDF /> : null}
            {this.props.report == 'tabe' ? <TabePDF /> : null}
            {this.props.report == 'tracing' ? <TracingPDF /> : null}
            {this.props.report == 'utilization' ? <UtilizationPDF /> : null}
            {this.props.report == 'edhistory' ? <EdHistoryPDF /> : null}
            {this.props.report == 'recidivism' ? <RecidivismPDF /> : null}
          </div> : null}
          {this.props.reports[this.props.report] && this.props.reports[this.props.report].data.displayType == 'csv' ? <div id='csvs'>
            {this.props.report == 'quarterly' ? <QuarterlyCSV /> : null}
          </div> : null}
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { ...state.reports, tab: state.drawer.tab, staff: state.staff.staff } },
  { ...ReportsStore.actionCreators, ...PanesStore.actionCreators }
)(Reports as any)
