import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import * as DrawerStore from '../../store/reducers/drawer'
import * as PanesStore from '../../store/reducers/panes'
import PermissionCells from './AccessPermissionCells'
import {
  Container,
  Table,
  Modal,
  Form,
  Button,
  Checkbox,
  Input,
  Select,
  Radio,
  Popup,
  Label
} from 'semantic-ui-react'
const _ = require('underscore')

type AccessProps =
    { access: any, tab: string } &
    typeof AdminStore.actionCreators &
    typeof DrawerStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class AccessPresets extends React.PureComponent<AccessProps> {
    public componentDidMount() {
        this.props.fetchAccessPresets()
    }

    public render() {
      return (
        <Container fluid style={{overflowY:'auto',overflowX:'hidden',height:720}}>
          <Form>
            <Form.Group>
              <Form.Field
                control={Select}
                placeholder='Department'
                options={[{key:0,value:null,text:''}].concat(this.props.access.options.departments)}
                value={this.props.access.add.departmentID}
                onChange={(e:any, data:any) => this.props.selectDepartment(data.value as number)}
              />
              {this.props.access.add.departmentID ? <Form.Field
                control={Select}
                placeholder='Preset'
                options={[{key:-1,value:null,text:''}].concat(this.props.access.options.presets.concat([{key:0,value:'create',text:'Create new...'}]))}
                value={this.props.access.add.preset}
                onChange={(e:any, data:any) => this.props.selectPreset(data.value as string)}
              /> : null}
              {this.props.access.add.departmentID && this.props.access.add.preset && this.props.access.add.preset != 'create' ? <Form.Field
                control={Select}
                placeholder='Preset Type'
                options={[{key:0,value:'facility',text:'Facility'},{key:1,value:'department',text:'Department'}]}
                value={this.props.access.add.presetType}
                onChange={(e:any, data:any) => this.props.selectPresetType(data.value as string)}
              /> : null}
              {this.props.access.add.departmentID && this.props.access.add.preset == 'create' ? <Fragment>
                <Form.Field
                  control={Input}
                  placeholder="New Preset"
                  value={this.props.access.add.newPreset}
                  onChange={(e:any, data:any) => this.props.updateNewPreset(data.value.validateAccess())}
                  width={4}
                />
                <Button positive content='Create' onClick={_.debounce(this.props.createPreset, 10000, true)} style={{marginBottom:10}} />
              </Fragment> : null}
            </Form.Group>
          </Form>
          {this.props.access.add.departmentID && this.props.access.add.preset && this.props.access.add.preset != 'create' ? <Fragment>
            <Table compact celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Subsystem</Table.HeaderCell>
                  <Table.HeaderCell>Access Type</Table.HeaderCell>
                  <Table.HeaderCell>View</Table.HeaderCell>
                  <Table.HeaderCell>Edit</Table.HeaderCell>
                  <Table.HeaderCell>Add</Table.HeaderCell>
                  <Table.HeaderCell>Del</Table.HeaderCell>
                  <Table.HeaderCell>None</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.access.add.presetType == 'department' ? <Table.Row>
                  <Table.Cell rowSpan={9} style={{ verticalAlign: 'top' }}><b>Admin</b></Table.Cell>
                  <PermissionCells display="System Administrator" subsystem="admin" module="system" options={['D']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                <Table.Row>
                  {this.props.access.add.presetType == 'facility' ? <Table.Cell rowSpan={2} style={{ verticalAlign: 'top' }}><b>Admin</b></Table.Cell> : null}
                  <PermissionCells display="Staff" subsystem="admin" module="staff" department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                <Table.Row>
                  <PermissionCells display="Opening Messages" subsystem="admin" module="messages" options={['V','E']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                {this.props.access.add.presetType == 'department' ? <Fragment>
                  <Table.Row>
                    <PermissionCells display="Programs" subsystem="admin" module="programs" department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                  </Table.Row>
                  <Table.Row>
                    <PermissionCells display="Facilities" subsystem="admin" module="facilities" department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                  </Table.Row>
                  <Table.Row>
                    <PermissionCells display="Access Levels" subsystem="admin" module="access" department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                  </Table.Row>
                  <Table.Row>
                    <PermissionCells display="Menu Items" subsystem="admin" module="menus" department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                  </Table.Row>
                  <Table.Row>
                    <PermissionCells display="Schools" subsystem="admin" module="schools" department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                  </Table.Row>
                  <Table.Row>
                    <PermissionCells display="Holidays" subsystem="admin" module="holidays" department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                  </Table.Row>
                </Fragment> : null}
                <Table.Row>
                  <Table.Cell rowSpan={this.props.access.add.departmentID == 1 || this.props.access.add.departmentID == 2 ? 6 : 5} style={{ verticalAlign: 'top' }}><b>Inmates</b></Table.Cell>
                  <PermissionCells display="Info" subsystem="inmates" module="info" options={['V','E']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                <Table.Row>
                  <PermissionCells display="Classes" subsystem="inmates" module="classes" department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                {this.props.access.add.departmentID == 1 ? <Table.Row>
                  <PermissionCells display="Tests" subsystem="inmates" module="tests" department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 2 ? <Table.Row>
                  <PermissionCells display="Substance Abuse" subsystem="inmates" module="substance" department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                <Table.Row>
                  <PermissionCells display="Careers" subsystem="inmates" module="careers" options={['V','E','A','D']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                <Table.Row>
                  <PermissionCells display="Printouts" subsystem="inmates" module="pdfs" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                <Table.Row>
                  <PermissionCells display="Admin Review" subsystem="inmates" module="review" department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                <Table.Row>
                  <Table.Cell rowSpan={5} style={{ verticalAlign: 'top' }}><b>Classes</b></Table.Cell>
                  <PermissionCells display="Info" subsystem="classes" module="info" options={['V','E','A','D']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                <Table.Row>
                  <PermissionCells display="Roster" subsystem="classes" module="roster" department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                <Table.Row>
                  <PermissionCells display="Attendance" subsystem="classes" module="attendance" options={['V','E','A','D']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                <Table.Row>
                  <PermissionCells display="Completions" subsystem="classes" module="completions" options={['V','A']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                <Table.Row>
                  <PermissionCells display="Waitlists" subsystem="classes" module="waitlists" options={['V','A','D']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                <Table.Row>
                  <Table.Cell rowSpan={1} style={{ verticalAlign: 'top' }}><b>Schedules</b></Table.Cell>
                  <PermissionCells display="Schedules" subsystem="schedules" module="schedules" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                <Table.Row>
                  <Table.Cell rowSpan={18} style={{ verticalAlign: 'top' }}><b>Reports</b></Table.Cell>
                  <PermissionCells display="Classroom Utilization" subsystem="reports" module="utilization" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row>
                {this.props.access.add.departmentID == 1 ? <Table.Row>
                  <PermissionCells display="Contact Tracing" subsystem="reports" module="tracing" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 1 ? <Table.Row>
                  <PermissionCells display="CSPR" subsystem="reports" module="cspr" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 1 ? <Table.Row>
                  <PermissionCells display="Quarterly" subsystem="reports" module="quarterly" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 1 ? <Table.Row>
                  <PermissionCells display="Full Roster" subsystem="reports" module="roster" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 1 ? <Table.Row>
                  <PermissionCells display="Funding Sources" subsystem="reports" module="funding" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 1 ? <Table.Row>
                  <PermissionCells display="Educational History" subsystem="reports" module="edhistory" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 1 ? <Table.Row>
                  <PermissionCells display="GED Tests" subsystem="reports" module="ged" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 1 ? <Table.Row>
                  <PermissionCells display="HiSET Tests" subsystem="reports" module="hiset" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 1 ? <Table.Row>
                  <PermissionCells display="RIASEC Tests" subsystem="reports" module="riasec" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 1 ? <Table.Row>
                  <PermissionCells display="Kamakani Report" subsystem="reports" module="kamakani" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 1 ? <Table.Row>
                  <PermissionCells display="Program Description" subsystem="reports" module="descriptions" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 2 ? <Table.Row>
                  <PermissionCells display="Admissions & Discharges" subsystem="reports" module="ands" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 2 ? <Table.Row>
                  <PermissionCells display="Screenings Drug of Choice" subsystem="reports" module="screenings" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
                {this.props.access.add.departmentID == 2 ? <Table.Row>
                  <PermissionCells display="UAs" subsystem="reports" module="uas" options={['V']} department={this.props.access.add.departmentID} preset={this.props.access.add.preset} presetType={this.props.access.add.presetType} presets={this.props.access.presets} editPreset={this.props.editPreset} />
                </Table.Row> : null}
              </Table.Body>
            </Table>
            <Button positive content='Save' onClick={_.debounce(this.props.savePreset, 10000, true)} style={{marginBottom:10}} />
            <Button negative content='Remove' onClick={_.debounce(this.props.removePreset, 10000, true)} style={{marginBottom:10}} />
          </Fragment> : null}
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { access: state.admin.access, tab: state.drawer.tab } },
  { ...DrawerStore.actionCreators, ...PanesStore.actionCreators, ...AdminStore.actionCreators }
)(AccessPresets as any)